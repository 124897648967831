import { all, put, takeLeading, select, call } from 'redux-saga/effects'

import API, { CallReturnType, IFetchMultipleQueryOptions, IProduct, TProduct } from '../../../api'
import { displayErrorToastSaga, DEFAULT_PAGE_SIZE, FACETS, ensureError } from '../../../lib'
import { storeQueryToAPIQuery, setProductSearchQueryString } from './utils'
import { productsListingSelector } from './selectors'
import * as actions from './actions'

function* fetchProducts(action: ReturnType<typeof actions.FETCH_PRODUCTS.request>) {
  try {
    const { key, query } = action.payload
    const selectListing: ReturnType<typeof productsListingSelector> =
      yield select(productsListingSelector)
    const listing = selectListing(key)
    const { totalCount } = listing
    if (totalCount !== undefined) {
      // already fetched
      yield put(actions.FETCH_PRODUCTS.cancel(key))
      return
    }

    const { filters, ...rest } = storeQueryToAPIQuery(query)
    const request = {
      ...rest,
      filters: {
        ...filters,
        is_available: 'any',
      },
      pageSize: query.pageSize || DEFAULT_PAGE_SIZE,
    }
    console.log(query)
    console.log(rest)
    console.log(request)
    const response: CallReturnType<typeof API.fetchProducts | typeof API.fetchPublicProducts> =
      API.hasAuth() ? yield API.fetchProducts(request) : yield API.fetchPublicProducts(request)
    yield put(
      actions.FETCH_PRODUCTS.success({
        key: action.payload.key,
        response,
      }),
    )
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.FETCH_PRODUCTS.failure(error))
  }
}

function* fetchMoreProducts(action: ReturnType<typeof actions.FETCH_MORE_PRODUCTS.request>) {
  try {
    const selectListing: ReturnType<typeof productsListingSelector> =
      yield select(productsListingSelector)
    const { moreUrl } = selectListing(action.payload)

    const response: CallReturnType<typeof API.fetchProducts | typeof API.fetchPublicProducts> =
      API.hasAuth() ? yield API.fetchProducts(moreUrl!) : yield API.fetchPublicProducts(moreUrl!)
    yield put(
      actions.FETCH_MORE_PRODUCTS.success({
        key: action.payload,
        response,
      }),
    )
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.FETCH_MORE_PRODUCTS.failure(error))
  }
}

function* sortProducts(action: ReturnType<typeof actions.SORT_PRODUCTS>) {
  const { request: key, field } = action.payload
  const selectListing: ReturnType<typeof productsListingSelector> =
    yield select(productsListingSelector)
  const { query } = selectListing(key)
  if (!query) {
    return
  }

  if (!field) {
    delete query.sort
  } else {
    query.sort = [field]
  }
  setProductSearchQueryString(query)
}

function* addFacet(action: ReturnType<typeof actions.ADD_FACET>) {
  const { request: key, field } = action.payload
  const selectListing: ReturnType<typeof productsListingSelector> =
    yield select(productsListingSelector)
  const { query, extra: facets } = selectListing(key)
  if (!query || !facets) {
    return
  }

  const { selected, available } = facets
  const filters = selected.map((facet) => ({
    key: facet.field,
    value: facet.value,
  }))
  available.forEach((facet) => {
    if (facet.field === field) {
      filters.push({
        key: field,
        value: facet.value,
      })
    }
  })

  // changing the query string will cause a refresh of the products
  setProductSearchQueryString({
    ...query,
    filters,
  })
}

function* removeFacet(action: ReturnType<typeof actions.REMOVE_FACET>) {
  const { request: key, field } = action.payload
  const selectListing: ReturnType<typeof productsListingSelector> =
    yield select(productsListingSelector)
  const { query, extra: facets } = selectListing(key)
  if (!query || !facets) {
    return
  }

  const { selected } = facets
  const filters = selected
    .filter((facet) => facet.field !== field)
    .map((facet) => ({
      key: facet.field,
      value: facet.value,
    }))

  // changing the query string will cause a refresh of the products
  setProductSearchQueryString({
    ...query,
    filters,
  })
}

function* setFacets(action: ReturnType<typeof actions.SET_FACETS>) {
  const { request: key, fields } = action.payload
  const selectListing: ReturnType<typeof productsListingSelector> =
    yield select(productsListingSelector)
  const { query } = selectListing(key)
  if (!query) {
    return
  }

  const filters = FACETS.filter((facet) => fields.includes(facet.field)).map((facet) => ({
    key: facet.field,
    value: facet.value,
  }))

  // changing the query string will cause a refresh of the products
  setProductSearchQueryString({
    ...query,
    filters,
  })
}

function* removeAllFacets(action: ReturnType<typeof actions.REMOVE_ALL_FACETS>) {
  const selectListing: ReturnType<typeof productsListingSelector> =
    yield select(productsListingSelector)
  const { query, extra: facets } = selectListing(action.payload)
  if (!query || !facets) {
    return
  }

  // changing the query string will cause a refresh of the products
  setProductSearchQueryString({
    ...query,
    filters: undefined,
  })
}

function* fetchProduct(action: ReturnType<typeof actions.FETCH_PRODUCT.request>) {
  try {
    const product: CallReturnType<typeof API.fetchProduct | typeof API.fetchPublicProduct> =
      API.hasAuth()
        ? yield API.fetchProduct(action.payload)
        : yield API.fetchPublicProduct(action.payload)
    yield put(actions.FETCH_PRODUCT.success(product))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.FETCH_PRODUCT.failure(error))
  }
}

function* fetchProductSubstitutes(
  action: ReturnType<typeof actions.FETCH_PRODUCT_SUBSTITUTES.request>,
) {
  try {
    const { productId, substituteIds } = action.payload

    const request = {
      filters: {
        id__in: substituteIds.join('__'),
        is_available: 'true',
      },
    }
    const response: CallReturnType<typeof API.fetchProducts | typeof API.fetchPublicProducts> =
      API.hasAuth() ? yield API.fetchProducts(request) : yield API.fetchPublicProducts(request)
    yield put(
      actions.FETCH_PRODUCT_SUBSTITUTES.success({
        productId,
        response,
      }),
    )
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.FETCH_PRODUCT_SUBSTITUTES.failure(error))
  }
}

function* fetchItemSubstitutes(action: ReturnType<typeof actions.FETCH_ITEM_SUBSTITUTES.request>) {
  const { parentKey, items } = action.payload

  const productIds = items.reduce<string[]>(
    (ids, { substituteIds }) => [...ids, ...substituteIds],
    [],
  )

  const products: IProduct[] = []
  if (productIds.length) {
    const request: IFetchMultipleQueryOptions = {
      filters: {
        id__in: productIds.join('__'),
        is_available: 'true',
      },
      pageSize: 100,
    }

    try {
      const response: CallReturnType<typeof API.fetchProducts> = yield API.fetchProducts(request)

      let nextUrl = response.links.next
      for (const product of response.data) {
        products.push(product)
      }

      while (nextUrl) {
        const response: CallReturnType<typeof API.fetchProducts> = yield API.fetchProducts(nextUrl)
        nextUrl = response.links.next
      }
    } catch (e) {
      const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
      yield put(actions.FETCH_ITEM_SUBSTITUTES.failure(error))
    }
  }

  const response: {
    productId: string
    substitutes: TProduct[]
  }[] = []

  for (const item of items) {
    const { substituteIds } = item
    const substitutes: TProduct[] = []
    for (const id of substituteIds) {
      const product = products.find((p) => p.id === id)
      if (product) {
        substitutes.push(product)
      }
    }
    response.push({
      productId: item.productId,
      substitutes,
    })
  }

  yield put(
    actions.FETCH_ITEM_SUBSTITUTES.success({
      parentKey,
      items: response,
    }),
  )
}

export function* saga() {
  yield all([
    takeLeading(actions.FETCH_PRODUCTS.request, fetchProducts),
    takeLeading(actions.FETCH_PRODUCTS.failure, displayErrorToastSaga),
    takeLeading(actions.FETCH_MORE_PRODUCTS.request, fetchMoreProducts),
    takeLeading(actions.FETCH_MORE_PRODUCTS.failure, displayErrorToastSaga),
    takeLeading(actions.SORT_PRODUCTS, sortProducts),
    takeLeading(actions.ADD_FACET, addFacet),
    takeLeading(actions.REMOVE_FACET, removeFacet),
    takeLeading(actions.SET_FACETS, setFacets),
    takeLeading(actions.REMOVE_ALL_FACETS, removeAllFacets),
    takeLeading(actions.FETCH_PRODUCT.request, fetchProduct),
    takeLeading(actions.FETCH_PRODUCT.failure, displayErrorToastSaga),
    takeLeading(actions.FETCH_PRODUCT_SUBSTITUTES.request, fetchProductSubstitutes),
    takeLeading(actions.FETCH_PRODUCT_SUBSTITUTES.failure, displayErrorToastSaga),
    takeLeading(actions.FETCH_ITEM_SUBSTITUTES.request, fetchItemSubstitutes),
    takeLeading(actions.FETCH_ITEM_SUBSTITUTES.failure, displayErrorToastSaga),
  ])
}
