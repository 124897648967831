import {
  AspectRatio,
  Box,
  BoxProps,
  Button,
  Image,
  Stack,
  StackDivider,
  Text,
  forwardRef,
} from '@chakra-ui/react'
import {
  IBasketDiscountBxgyAny,
  IBasketDiscountFreeProductPromotionWithProduct,
  IJsonApiModelWithId,
  IProduct,
} from '../../api'
import { OmFrozen, OmPromotion, OmClock } from '../icons'
import { useDiscounts } from '../../lib/hooks/use-discounts'
import { IconBadge } from '../icon-badge'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { APPLY_BXGY, APPLY_FREE_PRODUCT_PROMOTION } from '../../stores/order'

export interface IDiscountsListProps extends BoxProps {
  basket: IJsonApiModelWithId
}

export const DiscountsList = forwardRef<IDiscountsListProps, 'div'>((props, ref) => {
  const { basket, ...rest } = props
  const discounts = useDiscounts(basket.id)
  const { isFetching, item: discount } = discounts

  if (isFetching || !discount) {
    return null
  }

  const { bxgys, freeProductPromotions } = discount
  if (!bxgys?.length && !freeProductPromotions?.length) {
    return null
  }

  return (
    <Box ref={ref} {...rest}>
      <Text fontSize="lg" fontWeight="bold" mb={4}>
        Free Product Promotions
      </Text>
      <Stack direction="column" spacing={4} divider={<StackDivider borderColor="gray.200" />}>
        {bxgys?.map((discount) => {
          return discount.products.map((product) => (
            <BxgyProductBox key={product.id} product={product} bxgy={discount} />
          ))
        })}

        {freeProductPromotions?.map((discount) => (
          <FreeProductPromotionBox key={discount.id} freeProductPromotion={discount} />
          // <Text key={discount.id} fontSize="sm">
          //   {discount.title ?? 'Free product promotion'}
          // </Text>
        ))}
      </Stack>
    </Box>
  )
})

type BxgyProductBoxProps = {
  bxgy: IBasketDiscountBxgyAny
  product: IProduct
}

const BxgyProductBox = ({ bxgy, product }: BxgyProductBoxProps) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const handleClick = useCallback(() => {
    setIsLoading(true)
    dispatch(
      APPLY_BXGY.request({
        bxgyId: bxgy.id,
        productId: product.id,
      }),
    )
  }, [bxgy.id, dispatch, product.id])
  return (
    <DiscountProductBox product={product}>
      <Button size="sm" onClick={handleClick} isLoading={isLoading}>
        Add to Order
      </Button>
    </DiscountProductBox>
  )
}

type FreeProductPromotionBoxProps = {
  freeProductPromotion: IBasketDiscountFreeProductPromotionWithProduct
}

const FreeProductPromotionBox = ({ freeProductPromotion }: FreeProductPromotionBoxProps) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const handleClick = useCallback(() => {
    setIsLoading(true)
    dispatch(
      APPLY_FREE_PRODUCT_PROMOTION.request({
        freeProductPromotionId: freeProductPromotion.id,
      }),
    )
  }, [dispatch, freeProductPromotion.id])
  return (
    <DiscountProductBox product={freeProductPromotion.product}>
      <Button size="sm" onClick={handleClick} isLoading={isLoading}>
        Add to Order
      </Button>
    </DiscountProductBox>
  )
}

type DiscountProductBoxProps = {
  product: IProduct
  children: React.ReactElement
}

const DiscountProductBox = ({ product, children }: DiscountProductBoxProps) => {
  const images = product.images.length ? product.images.filter((image) => image.medium) : []
  const image = images.length ? product.images[0].medium : '/images/product-placeholder.png'
  const hasIcons = product.isFrozen || product.isOnPromotion || product.isPreOrder

  const imageSize = ['2rem', '4rem']

  return (
    <Box>
      <Box display="flex">
        <Box width={imageSize} height={imageSize} minWidth={imageSize}>
          <AspectRatio maxW={imageSize} ratio={1}>
            <Box
              position="absolute"
              top={0}
              left={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="100%">
              <Image
                src={image}
                width="100%"
                height="100%"
                objectFit="cover"
                alt={product.name}
                borderRadius="sm"
              />
              {hasIcons && (
                <Box top={1} left={1} position="absolute">
                  {product.isFrozen && (
                    <IconBadge icon={OmFrozen} size="xs" color="#82D0F6" marginRight={1} />
                  )}
                  {product.isOnPromotion && (
                    <IconBadge icon={OmPromotion} size="xs" color="coral.500" marginRight={1} />
                  )}
                  {product.isPreOrder && (
                    <IconBadge icon={OmClock} size="xs" color="birchallBlue.500" marginRight={1} />
                  )}
                </Box>
              )}
            </Box>
          </AspectRatio>
        </Box>
        <Box
          flexDirection="column"
          justifyContent="space-between"
          px={2}
          fontWeight="bold"
          fontSize="sm">
          <Box>
            <Box as="span" display="block" color="gray.500" textDecoration="none">
              {product.id}
            </Box>
            <Box as="span" display="block">
              {product.name}
            </Box>
            <Box mt={2}>{children}</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
